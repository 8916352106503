import '@webcomponents/custom-elements';
import 'zone.js';

if (typeof window !== "undefined") {
  (window as any).debugLog2 = (...args: any[]) => {
    if (window.location.href.indexOf("https://t-port-mng.tmls.jp") === -1) {
      console.log(...args);
    }
  };
}

(window as any).process = {
  env: { DEBUG: undefined },
};

// "global is not defined"の対応（aws-sdkを呼び出す機能で発生）
(window as any).global = window;

// "Buffer is not defined"の対応（aws-sdkを呼び出す機能で発生）
global.Buffer = global.Buffer || require('buffer').Buffer;
